import React from "react";

import { Header } from "../components/parts/Header";
import { Company } from "../components/Company";
import { Footer } from "../components/Footer";

import { Layout } from "../components/parts/Layout";

const CompanyPage = () => {
  return (
    <Layout>
      <Header
        title={"運営会社"}
        description={"運営会社"}
      />
      <Company />
      <Footer />
    </Layout>
  )
}

export default CompanyPage
