import React from "react";
import styled from "styled-components";

import { Container } from "../components/parts/Container";
import { TitleView } from "../components/parts/TitleView";
import { WhiteWrapper } from "../components/parts/WhiteWrapper";
import { Button } from "../components/parts/Button";

import { Responsive, FontSize } from "../utils/Const";

const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const CompanyTable = styled.div`
  width: 100%;
  max-width: 80%;
  margin: 4rem 0;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0;
  @media(max-width: ${Responsive.PC}px) {
    flex-direction: column;
  }
`

const Title = styled.h2`
  width: 100%;
  max-width: 200px;
  text-align: center;
  font-size: ${FontSize.xs};
  @media(max-width: ${Responsive.PC}px) {
    text-align: left;
  }
`

const Description = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 1rem;
  box-sizing: border-box;
  @media(max-width: ${Responsive.PC}px) {
    padding: 0 0;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Company = () => {
  return(
    <WhiteWrapper>
      <Container>
        <TitleView>運営会社</TitleView>
        <CompanyWrapper>
          <CompanyTable>
            <Row>
              <Title>会社名</Title>
              <Description>ネクストライブ株式会社 (英名：Nextribe Inc.)</Description>
            </Row>
            <Row>
              <Title>所在地</Title>
              <Description>〒553-0003<br />大阪市福島区福島1-4-4セントラル70 3F</Description>
            </Row>
          </CompanyTable>
          <ButtonWrapper>
            <Button href={`https://nextribe.co.jp/company`}>会社概要はこちら</Button>
          </ButtonWrapper>
        </CompanyWrapper>
      </Container>
    </WhiteWrapper>
  );
}